/* FloatingWhatsAppButton.css */

.floating-whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .whatsapp-icon {
    width: 60px; /* Ajuste o tamanho conforme necessário */
    height: 60px; /* Ajuste o tamanho conforme necessário */
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  
  .whatsapp-icon:hover {
    transform: scale(1.1);
  }
  